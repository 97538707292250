import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import allActions from 'redux/actions';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import axios from '../../axios/';


export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { USER_BULL_BALANCE, connectedChainId, NETWORKS } = useSelector(state => state.auth);

    const [show, setShow] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const [typeOfStaking, setTypeOfStaking] = useState(0);

    const [ThreeMLocked, setThreeMLocked] = useState([]);
    const [SixMLocked, setSixMLocked] = useState([]);
    const [YearMLocked, setYearMLocked] = useState([]);

    const [timer, setTimer] = useState();
    const [isVaultsClosed, setisVaultsClosed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [lockValue, setLockValue] = useState(false);
    const [totalStacked, setTotalStacked] = useState(false);
    const [userMaxAllowedStakeLimit, setUserMaxAllowedStakeLimit] = useState(0);
    const [pools, setPools] = useState([]);
    const [isFetched, setisFetched] = useState(false);
    const [availableToStaking, setAvailableToStaking] = useState(0);

    const { isConnected, address, chainId } = useWeb3ModalAccount();
    // const address = '0x8DAfccB0bcD8539eaB6f90887Db0CE4670323b25';

    const { walletProvider } = useWeb3ModalProvider();

    useEffect(() => {
        if (isConnected) {
            dispatch({ type: 'LOAD_WALLET_ADDRESS', payload: address });
            const web3Provider = new ethers.providers.Web3Provider(walletProvider);
            dispatch({ type: 'LOAD_WEB3_PROVIDER', payload: web3Provider });
            reset();
            refetchData();
        } else {
            navigate('/login');
        }
        getTimeDifference();
    }, [isConnected, address]);

    const reset = () => {
        setisFetched(false);
        setThreeMLocked([]);
        setSixMLocked([]);
        setYearMLocked([]);
        setPools([]);
        setLockValue('');
        setUserMaxAllowedStakeLimit(0);
        setAvailableToStaking(0);
    };

    const refetchData = () => {
        numberOfStakes();
        getMaxStakingAllowedLimit();
        getUserStakedAmountInAllVaults();
        getLimitLeftInAllPool();
    }

    const getMaxStakingAllowedLimit = async () => {
        try {
            var { response: { data: { amount } } } = await axios.get(`/reward/getStakingLimit?walletAddress=${address}`);
            if (amount) {
                setUserMaxAllowedStakeLimit((amount / Math.pow(10, 18)).toLocaleString(undefined, { useGrouping: false }));
            } else {
                setUserMaxAllowedStakeLimit(0);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getUserStakedAmountInAllVaults = async () => {
        try {
            let amount = await dispatch(allActions.stakingActions.userStakedAmount());
            setTotalStacked((amount / Math.pow(10, 18)).toLocaleString(undefined, { useGrouping: false }));
        } catch (error) {
            console.error(error);
        };
    };

    const getLimitLeftInAllPool = async () => {
        try {
            let array = [];

            let poolsDetails = await dispatch(allActions.stakingActions.getMultiplePoolDetails());

            for (let i = 0; i < poolsDetails.length; i++) {
                let tokenLimit = (poolsDetails[i].tokenLimit.toString() / Math.pow(10, 18)).toString();
                let totalStaked = (poolsDetails[i].totalStaked.toString() / Math.pow(10, 18)).toString();
                array.push({ tokenLimit, totalStaked, i });
                if (i === 2) {
                    setPools(array);
                    setisFetched(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const numberOfStakes = async () => {
        dispatch(allActions.stakingActions.balanceOfERC20());

        let numberOfStakes = await dispatch(allActions.stakingActions.numberOfStakes());

        if (numberOfStakes > 0) {
            let getMultipleStakeInfo = await dispatch(allActions.stakingActions.getMultipleStakeInfo(numberOfStakes));
            // reset();
            for (let i = 0; i < getMultipleStakeInfo.length; i++) {
                let amount = getMultipleStakeInfo[i].amount.toString() / Math.pow(10, 18);
                let unlockTime = getMultipleStakeInfo[i].unlockTime.toString();
                let month = getMultipleStakeInfo[i].months.toString();
                if (month === '3') {
                    setThreeMLocked(prev => [...prev, { amount, unlockTime, month, i }]);
                } else if (month === '6') {
                    setSixMLocked(prev => [...prev, { amount, unlockTime, month, i }])
                } else if (month === '12') {
                    setYearMLocked(prev => [...prev, { amount, unlockTime, month, i }])
                }
            };
        }
    };

    const stakeAmount = async () => {
        setIsLoading(true);
        let amount = lockValue * Math.pow(10, 18);
        let filteredAmount = amount.toLocaleString(undefined, { useGrouping: false });
        try {
            let spendingLimit = await dispatch(allActions.stakingActions.allowance(filteredAmount));
            if (Number(spendingLimit) >= Number(lockValue)) {
                let { response: { data } } = await axios.get(`/reward/getSignatureForBullStaking?stakeAmount=${filteredAmount}&walletAddress=${address}`)
                data['poolId'] = typeOfStaking;
                await dispatch(allActions.stakingActions.stakeTokens(data));
                reset();
                refetchData();
            } else {
                alert(`Please allow ${lockValue} limit`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setShow(false);
            setLockValue('');
        }
    };

    const unstakeAmount = async () => {
        try {
            setIsLoading(true);
            let now = moment().valueOf();
            let data = typeOfStaking === 0 ? ThreeMLocked : typeOfStaking === 1 ? SixMLocked : YearMLocked;
            let ids = [];
            data.forEach(item => {
                if (Number(item.unlockTime) < (now / 1000)) {
                    ids.push(item.i);
                }
            });
            await dispatch(allActions.stakingActions.unstakeMultiple(ids));
            reset()
            refetchData();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setShowWithdrawModal(false);
        }
    };

    const getTotalLockedAmount = (data) => {
        let totalAmount = 0;
        data.forEach(item => totalAmount += item.amount);
        return totalAmount;
    };

    const getTotalAvailableForWithdrawAmount = (data) => {
        let totalAmount = 0;
        let now = moment().valueOf();
        data.forEach(item => {
            if (Number(item.unlockTime) < (now / 1000)) {
                totalAmount += item.amount;
            }
        });
        return totalAmount;
    };

    const getPercentage = () => {
        return ((lockValue / availableToStaking) * 100).toFixed(2);
    };

    const switchNetwork = async () => {
        dispatch(allActions.authActions.switchNetwork());
    };

    const splitWalletAddress = () => {
        if (address) {
            return (`${address.substring(0, 5)}...${address.substring(address.length - 5, address.length)}`);
        }
    };

    const getTimeDifference = async () => {

        var a = setInterval(() => {
            let time = 1707490800;
            var nowTime = moment().valueOf() / 1000;
            var now = moment().format('DD/MM/YYYY HH:mm:ss');
            var ms = moment(Number(time) * 1000).diff(moment(now, 'DD/MM/YYYY HH:mm:ss'));
            let { _data } = moment.duration(ms);
            setTimer(_data);
            if (time < nowTime) {
                setisVaultsClosed(true);
                clearInterval(a);
            }
        }, 1000);
    }

    const checkAvailableForLocking = (poolId) => {
        if (isFetched) {
            let max = pools[poolId].tokenLimit - pools[poolId].totalStaked;
            let roundedValue = Math.min(USER_BULL_BALANCE, (Number(userMaxAllowedStakeLimit) - Number(totalStacked)), max);
            let splitRoundedValue = roundedValue.toString().split('.');
            return splitRoundedValue[0];
        } else {
            return 0;
        }
    };

    const disableLockButton = (poolid) => {
        if (isFetched) {
            if (isLoading || isVaultsClosed || userMaxAllowedStakeLimit === 0 || pools[poolid].tokenLimit - pools[poolid].totalStaked === 0 || userMaxAllowedStakeLimit - totalStacked === 0) {
                return true
            } else {
                return false
            }
        }
    };

    const showLockModal = async (poolId) => {
        let limit = await checkAvailableForLocking(poolId);
        setAvailableToStaking(limit);
        setTypeOfStaking(poolId);
        setShow(true)
    };

    return (
        <div className={styles.box}>
            <Modal
                show={show}
                backdrop="true"
                onHide={() => { setLockValue(''); setShow(false) }}
                keyboard={false}
                centered
                fullscreen={window.innerWidth <= 900 ? true : false}
                backdropClassName="modalBackdrop"
            >
                <Modal.Header>
                    <Modal.Title>
                        <img src="/assets/home/lock.svg" alt='lock' />
                        Locking
                    </Modal.Title>
                    <img src="/assets/home/close.svg" alt='lock' onClick={() => { setLockValue(''); setShow(false) }} style={{ cursor: 'pointer' }} />
                </Modal.Header>
                <Modal.Body>
                    <div className='upperSection'>
                        <div className='left'>
                            <label>
                                Available to Lock
                            </label>
                            <h1>{availableToStaking}<sub>BULL</sub></h1>
                        </div>
                        <div className='right'>
                            <label>
                                Duration
                            </label>
                            <h1>
                                {typeOfStaking === 0 ? 3 : typeOfStaking === 1 ? 6 : 12} Months
                            </h1>
                        </div>
                    </div>
                    <div className='inputArea'>
                        <img src="/assets/home/lock.svg" alt='lock' />
                        <input type='number' placeholder='Enter your Amount in BULL' value={lockValue || ''} className='inputBox' onChange={(e) => setLockValue(e.target.value)} />
                        <button onClick={() => setLockValue(availableToStaking)}>Max</button>
                    </div>
                    <div className='sliderWraper'>
                        <input
                            type='range'
                            className={styles.rangeSlider}
                            id="range3"
                            value={lockValue || 0}
                            min={0}
                            max={availableToStaking}
                            style={{ background: `linear-gradient(to right, #31c859 ${getPercentage()}%, #161616 ${getPercentage()}%)` }}
                            onChange={(e) => {
                                setLockValue(e.target.value)
                            }}
                        />
                        <div className='sliderInfo'>
                            <span>0</span>
                            <span>
                                {getPercentage() > 0 && (
                                    <>
                                        {getPercentage()}%
                                    </>
                                )}
                            </span>
                            <span>100%</span>
                        </div>
                    </div>
                    <div className='buttonSection'>
                        <Button disabled={isLoading || (Number(lockValue) <= 0 || Number(lockValue) > Number(availableToStaking))} className='connectButton' onClick={() => stakeAmount()}>
                            {isLoading ? 'Processing...' : 'Lock'}
                        </Button>

                        <div className='infoTag'>
                            <img src="/assets/home/info.svg" alt='lock' />
                            <p>
                                You will be able to withdraw the locked tokens after a period of <span>{typeOfStaking === 0 ? 3 : typeOfStaking === 1 ? 6 : 12}</span> Months
                            </p>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>

            <Modal
                show={showWithdrawModal}
                onHide={() => setShowWithdrawModal(false)}
                backdrop="true"
                keyboard={false}
                centered
                fullscreen={window.innerWidth <= 900 ? true : false}
                backdropClassName="modalBackdrop"
            >
                <Modal.Header>
                    <Modal.Title>
                        <img src="/assets/home/withdraw.svg" alt='lock' />
                        Withdrawal
                    </Modal.Title>
                    <img src="/assets/home/close.svg" alt='lock' onClick={() => setShowWithdrawModal(false)} style={{ cursor: 'pointer' }} />
                </Modal.Header>
                <Modal.Body>
                    <div className='upperSection'>
                        <div className='left'>
                            <label>
                                Available to Withdraw
                            </label>
                            <h1>
                                {getTotalAvailableForWithdrawAmount(typeOfStaking === 0 ? ThreeMLocked : typeOfStaking === 1 ? SixMLocked : YearMLocked)}
                                <sub>BULL</sub>
                            </h1>
                        </div>
                        <div className='right'>
                            <label>
                                Vault
                            </label>
                            <h1>
                                {typeOfStaking === 0 ? 3 : typeOfStaking === 1 ? 6 : 12} Months
                            </h1>
                        </div>
                    </div>
                    <div className='buttonSection'>
                        <button disabled={isLoading || getTotalAvailableForWithdrawAmount(typeOfStaking === 0 ? ThreeMLocked : typeOfStaking === 1 ? SixMLocked : YearMLocked) === 0} className='connectButton' onClick={() => unstakeAmount()}>
                            {isLoading ? 'Processing...' : 'Confirm'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={`${styles.head} container`}>
                <h1>
                    {connectedChainId} Beyond Airdrop for Investors <img src="/assets/login/rushicon.svg" alt='banner' />
                </h1>
                <p>
                    Lock <span>$BULL</span> tokens in one of the vaults below to be eligible for <span>$B</span> airdrop
                </p>
            </div>

            <div className={styles.infoBarOuter}>
                <div className='container'>
                    <div className={styles.infoBar}>
                        <div className={styles.leftSide}>
                            <div className={`${styles.timer} ${isVaultsClosed && styles.closed}`}>
                                {isVaultsClosed
                                    ? (
                                        <>
                                            <img src='/assets/info.png' alt='info' className={styles.infoIcon} />
                                            <h5>The time to lock has ended, the vaults are closed</h5>
                                        </>
                                    )
                                    : (
                                        <>
                                            <span>
                                                {timer?.days}<sub>Days</sub>
                                            </span>
                                            <span>
                                                {timer?.hours}<sub>Hours</sub>
                                            </span>
                                            <span>
                                                {timer?.minutes}<sub>Mins</sub>
                                            </span>
                                            <span>
                                                {timer?.seconds}<sub>Sec</sub>
                                            </span>
                                        </>
                                    )
                                }

                            </div>
                            {!isVaultsClosed && (
                                <div className={styles.info}>
                                    Before the vaults close
                                </div>
                            )}
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.coin}>
                                <img src="/assets/check.png" className={styles.bullIcon} alt="bullCoinIcon" />
                                <span className={styles.balance}>{USER_BULL_BALANCE.toLocaleString()}</span>
                                <span className={styles.symbol}>$BULL</span>
                            </div>
                            <div className={styles.walletAddress} >
                                {splitWalletAddress()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.terms} container`}>

                <div className={styles.term}>
                    <div className={styles.header}>
                        <h1>
                            <img src="/assets/home/3M.svg" alt='banner' /> 3 Month vault
                        </h1>
                        <p>
                            0% Bonus <img src="/assets/home/rush.svg" alt='banner' />
                        </p>
                    </div>

                    <div className={styles.info}>
                        <label>Total Locked</label>
                        <h1>
                            {getTotalLockedAmount(ThreeMLocked)}
                            <sub>BULL</sub>
                        </h1>
                    </div>

                    <div className={styles.info}>
                        <label>Available for Locking</label>
                        <h1>
                            {checkAvailableForLocking(0)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.lock} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={disableLockButton(0)} className={styles.lock} onClick={() => showLockModal(0)}>
                                    {isFetched
                                        ? (
                                            <>
                                                {userMaxAllowedStakeLimit === 0 ? 'This wallet is not eligible' : pools[0].tokenLimit - pools[0].totalStaked === 0 ? 'Vault Limit Reached' : userMaxAllowedStakeLimit - totalStacked === 0 ? 'Max Limit Reached' : 'Lock'}
                                            </>
                                        )
                                        : ('Lock')
                                    }
                                </button>
                            )
                        }
                    </div>

                    <div className={styles.info}>
                        <label>Available for withdrawal</label>
                        <h1>
                            {getTotalAvailableForWithdrawAmount(ThreeMLocked)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.withdraw} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={isLoading} className={styles.withdraw} onClick={() => { setTypeOfStaking(0); setShowWithdrawModal(true); }}>Withdraw</button>
                            )
                        }
                    </div>

                </div>

                <div className={styles.term}>
                    <div className={styles.header}>
                        <h1>
                            <img src="/assets/home/6M.svg" alt='banner' /> 6 Month vault
                        </h1>
                        <p>
                            10% Bonus <img src="/assets/home/rush.svg" alt='banner' />
                        </p>
                    </div>

                    <div className={styles.info}>
                        <label>Total Locked</label>
                        <h1>
                            {getTotalLockedAmount(SixMLocked)}
                            <sub>BULL</sub>
                        </h1>
                    </div>

                    <div className={styles.info}>
                        <label>Available for Locking</label>
                        <h1>
                            {checkAvailableForLocking(1)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.lock} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={disableLockButton(1)} className={styles.lock} onClick={() => showLockModal(1)}>
                                    {isFetched
                                        ? (
                                            <>
                                                {userMaxAllowedStakeLimit === 0 ? 'This wallet is not eligible' : pools[1].tokenLimit - pools[1].totalStaked === 0 ? 'Vault Limit Reached' : userMaxAllowedStakeLimit - totalStacked === 0 ? 'Max Limit Reached' : 'Lock'}
                                            </>
                                        )
                                        : ('Lock')
                                    }
                                </button>
                            )
                        }
                    </div>

                    <div className={styles.info}>
                        <label>Available for withdrawal</label>
                        <h1>
                            {getTotalAvailableForWithdrawAmount(SixMLocked)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.withdraw} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={isLoading} className={styles.withdraw} onClick={() => { setTypeOfStaking(1); setShowWithdrawModal(true); }}>Withdraw</button>
                            )
                        }
                    </div>

                </div>

                <div className={styles.term}>
                    <div className={styles.header}>
                        <h1>
                            <img src="/assets/home/12M.svg" alt='banner' />   12 Month vault
                        </h1>
                        <p>
                            20% Bonus <img src="/assets/home/rush.svg" alt='banner' />
                        </p>
                    </div>

                    <div className={styles.info}>
                        <label>Total Locked</label>
                        <h1>
                            {getTotalLockedAmount(YearMLocked)}
                            <sub>BULL</sub></h1>
                    </div>

                    <div className={styles.info}>
                        <label>Available for Locking</label>
                        <h1>
                            {checkAvailableForLocking(2)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.lock} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={disableLockButton(2)} className={styles.lock} onClick={() => showLockModal(2)}>
                                    {isFetched
                                        ? (
                                            <>
                                                {userMaxAllowedStakeLimit === 0 ? 'This wallet is not eligible' : pools[2].tokenLimit - pools[2].totalStaked === 0 ? 'Vault Limit Reached' : userMaxAllowedStakeLimit - totalStacked === 0 ? 'Max Limit Reached' : 'Lock'}
                                            </>
                                        )
                                        : ('Lock')
                                    }
                                </button>
                            )
                        }
                    </div>

                    <div className={styles.info}>
                        <label>Available for withdrawal</label>
                        <h1>
                            {getTotalAvailableForWithdrawAmount(YearMLocked)}
                            <sub>BULL</sub>
                        </h1>
                        {chainId !== parseInt(NETWORKS['POLYGON'].chainId)
                            ? (
                                <button className={styles.withdraw} onClick={() => switchNetwork()}>
                                    Change Network
                                </button>
                            )
                            : (
                                <button disabled={isLoading} className={styles.withdraw} onClick={() => { setTypeOfStaking(2); setShowWithdrawModal(true); }}>Withdraw</button>
                            )
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}
